export async function getListOrganizers(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.full)
    uriParams.append('full', params.full);

  if (params && params.with_auth_link)
    uriParams.append('with_auth_link', params.with_auth_link);


  this.commit('organizer/updateOrganizerListState', null)
  try {

    //let response = await this.$axios.get('organizers', {params: uriParams})
    let response = await this.$api('GET', 'organizers?' + new URLSearchParams(uriParams))

    this.commit('organizer/updateOrganizerListState', response)
  } catch (e) {}
}

export async function getTemplate(context, host) {
  try {
    const uriParams = new URLSearchParams();
    uriParams.append('host', host);


    this.commit('organizer/updateOrganizerTemplateState', null)
    //let response = await this.$axios.get('organizer/template', {params: uriParams})
    let response = await this.$api('GET', 'organizer/template?' + new URLSearchParams(uriParams))
    this.commit('organizer/updateOrganizerTemplateState', response)
  } catch (e) {}
}
