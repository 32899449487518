import { boot } from 'quasar/wrappers'

export default boot(({ app, store }) => {

  const rbac = (permission, event_id = null) => {

    let user = store.getters["user/userGetter"]

    let user_type = null
    let event_delegation = []

    if (user && user.type && user.delegation) {
      user_type = user.type
      event_delegation = user.delegation
    }

    if (user_type === 'super-admin')
      return true

    if (user_type === 'client')
      return true


    if (event_id !== null) {

      if (!event_delegation.hasOwnProperty(event_id))
        return false

      switch (permission) {
        case 'event.read':
          return ['admin', 'chrono', 'paddock', 'chef_piste', 'speaker', 'juge', 'commissaire'].includes(event_delegation[event_id])
        case 'event.settings.read':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        // timetable
        case 'event.timetable.read':
          return ['admin', 'chrono', 'juge'].includes(event_delegation[event_id])
        case 'event.timetable.add':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.timetable.edit':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.timetable.delete':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        // documents
        case 'event.documents.read':
          return ['admin', 'chrono', 'paddock', 'chef_piste', 'speaker', 'juge', 'commissaire'].includes(event_delegation[event_id])
        case 'event.documents.add':
          return ['admin', 'chrono', 'chef_piste'].includes(event_delegation[event_id])
        case 'event.documents.edit':
          return ['admin', 'chrono', 'chef_piste'].includes(event_delegation[event_id])
        case 'event.documents.publish':
          return ['admin', 'chrono', 'chef_piste'].includes(event_delegation[event_id])
        case 'event.documents.delete':
          return ['admin', 'chrono', 'chef_piste'].includes(event_delegation[event_id])

        case 'event.scoreboard.read':
          return ['admin', 'chrono', 'speaker'].includes(event_delegation[event_id])
        case 'event.screen.read':
          return ['admin', 'chrono', 'speaker'].includes(event_delegation[event_id])
        case 'event.chrono.read':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.entries.upload':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.entries.export':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        // show
        case 'event.show.settings.read':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.settings.edit':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.entries.read':
          return ['admin', 'chrono', 'paddock', 'chef_piste', 'speaker', 'juge'].includes(event_delegation[event_id])
        case 'event.show.entries.manage':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.entries.disqualify':
          return ['admin', 'chrono', 'juge', 'paddock'].includes(event_delegation[event_id])
        case 'event.show.entries.ctrl_veto':
          return ['admin', 'chrono', 'juge', 'paddock'].includes(event_delegation[event_id])
        case 'event.show.entries.print.protocole':
          return ['admin', 'chrono', 'juge'].includes(event_delegation[event_id])
        case 'event.show.entries.print.protocoles':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.entries.print.dossard':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.entries.print.dossards':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.paperless.dressage':
          return ['admin', 'juge'].includes(event_delegation[event_id])
        case 'event.show.paperless.xc-emit':
          return ['admin', 'chrono', 'commissaire'].includes(event_delegation[event_id])
        case 'event.show.paperless.xc-receive':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.paperless.speaker':
          return ['admin', 'chrono', 'speaker'].includes(event_delegation[event_id])
        case 'event.show.print.inspection':
          return ['admin', 'chrono', 'juge', 'paddock', 'speaker'].includes(event_delegation[event_id])
        case 'event.show.print.ranking':
          return ['admin', 'chrono', 'juge', 'paddock', 'chef_piste', 'speaker'].includes(event_delegation[event_id])
        case 'event.show.print.report':
          return ['admin', 'chrono', 'juge', 'chef_piste', 'speaker'].includes(event_delegation[event_id])
        case 'event.show.entries.export':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        case 'event.show.entries.saisie':
          return ['admin', 'chrono'].includes(event_delegation[event_id])
        default:
          return false
      }

    }

    return false
  }


  app.config.globalProperties.$rbac = rbac;
})
