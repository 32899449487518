export async function getListRiders(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.with_auth_link)
    uriParams.append('with_auth_link', params.with_auth_link);

  if (params && params.with_email)
    uriParams.append('with_email', params.with_email);

  if (params && params.with_legals)
    uriParams.append('with_legals', params.with_legals);

  if (params && params.federation)
    uriParams.append('federation', params.federation);


  this.commit('rider/updateRiderListState', null)
  try {
    //let response = await this.$axios.get('riders', {params: uriParams})
    let response = await this.$api('GET', 'riders?' + new URLSearchParams(uriParams))

    this.commit('rider/updateRiderListState', response)
  } catch (e) {}

}

// /event/:id/show/:number/riders/numbering/shuffle
export async function putRiderNumberingShuffle(context, params) {
  let response = await this.$api('POST', 'event/' + params.event_id + '/show/' + params.show_id + '/riders/numbering/shuffle')
}

// /event/:id/show/:number/riders/numbering
export async function putRiderNumbering(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/riders/numbering', params.entries)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/riders/numbering', {body:params.entries})
}

// /event/:id/show/:number/rider/:rider/jumping
export async function putRiderJumpingResult(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/jumping', params)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/jumping', {body:params})
}

// /event/:id/show/:number/rider/:rider/dressage
export async function putRiderDressageResult(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/dressage', params)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/dressage', {body:params})
}

// /event/:id/show/:number/rider/:rider/eventing/xc-fence
export async function putRiderXCFenceResult(context, params) {
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/eventing/xc-fence', {body:params})
}

// /event/:id/show/:number/rider/:rider/hunter
export async function putRiderHunterResult(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/hunter', params)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/hunter', {body:params})
}

// /event/:id/show/:number/rider/:rider/eventing
export async function putRiderEventingResult(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/eventing', params.result)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/eventing', {body:params.result})
}

// /event/:id/show/:number/rider/:rider/derby
export async function putRiderDerbyResult(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/derby', params.result)
  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/derby', {body:params.result})
}

// /event/:id/show/:number/rider/:rider
export async function getRider(context, params) {
  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id)
    return await this.$api('GET', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id)
  } catch (e) {}
}

// /event/:id/show/:number/rider/:rider/swap-rider
export async function swapRider(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-rider?override=' + params.override, {body:params.entry, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-rider?override=' + params.override , params.entry)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/swap-horse
export async function swapHorse(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-horse?override=' + params.override, {body:params.entry, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-horse?override=' + params.override , params.entry)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/saction-horse
export async function sanctionHorse(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/sanction-horse?override=' + params.override, {body:params.entry, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/sanction-horse?override=' + params.override , params.entry)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/swap-show
export async function swapShow(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-show', {body:params.show_dest, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/' + params.rider_id + '/swap-show', params.show_dest)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/add
export async function addRider(context, params) {

  let response = await this.$api('POST', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/add?override=' + params.override, {body:params.entry, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.post('event/' + params.event_id + '/show/' + params.show_id + '/rider/add?override=' + params.override , params.entry)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/edit
export async function editRider(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/edit?override=' + params.override, {body:params.entry, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/edit?override=' + params.override , params.entry)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

export async function disqualification(context, params) {

  let response = await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/disqualification', {body:params, responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/disqualification', params)
  if (response.status !== 204) {
    throw response.data;
  }*/
}

// /event/:id/show/:number/rider/:rider/remove
export async function removeRider(context, params) {

  let response = await this.$api('DELETE', 'event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/remove', {responseType: 'text'})

  if (response)
    throw response

  /*let response = await this.$axios.delete('event/' + params.event_id + '/show/' + params.show_id + '/rider/'+ params.rider_id + '/remove')
  if (response.status !== 204) {
    throw response.data;
  }*/
}


// /riders/search
export async function searchRider(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.query)
    uriParams.append('query', params.query);

  if (params && params.federation)
    uriParams.append('federation', params.federation);

  try {
    return await this.$api('GET', 'riders/search?' + new URLSearchParams(uriParams))
  }
  catch (e) {
    throw e.message
  }

  /*let response = await this.$axios.get('riders/search', {params: uriParams})

  if (response.status !== 200) {
    throw response.data;
  }

  return response.data*/
}


// /horses/search
export async function searchHorse(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.query)
    uriParams.append('query', params.query);

  if (params && params.federation)
    uriParams.append('federation', params.federation);

  try {
    return await this.$api('GET', 'horses/search?' + new URLSearchParams(uriParams))
  }
  catch (e) {
    throw e.message
  }

  /*
  let response = await this.$axios.get('horses/search', {params: uriParams})

  if (response.status !== 200) {
    throw response.data;
  }

  return response.data*/
}


