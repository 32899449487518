
export function userGetter (state) {
  return state.user
}

export function eventListGetter (state) {
  return state.events
}

export function eventListOfficialGetter (state) {
  return state.eventsOfficial
}

