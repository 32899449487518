
export async function loginEquibel(context, params) {

  try {
    let response = await this.$api('POST', 'auth/equibel/login', {body:params})

  } catch (e) {
    throw e.message
  }

  return true
}

export async function loginFallback(context, params) {

  try {
    let response = await this.$api('POST', 'auth/fallback/login', {body:params})

  } catch (e) {
    throw e.message
  }

  return true
}
