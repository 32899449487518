
export async function getUser(context) {
  this.commit('user/updateUserState', null)
  try {
    //let response = await this.$axios.get('user')
    let response = await this.$api('GET', 'user')
    this.commit('user/updateUserState', response)
  }
  catch (e) {}
}

export async function putUser(context, params) {
  /*let response = await this.$axios.put('user', {
    firstname: params.firstname.trim().toLowerCase(),
    lastname: params.lastname.trim().toUpperCase(),
    email: params.email.trim().toLowerCase(),
  })*/

  let response = await this.$api('PUT', 'user', {
    body: {
      firstname: params.firstname.trim().toLowerCase(),
      lastname: params.lastname.trim().toUpperCase(),
      email: params.email.trim().toLowerCase(),
    }
  })


  this.commit('user/updateUserFirstname', params.firstname.trim().toLowerCase())
  this.commit('user/updateUserLastname', params.lastname.trim().toUpperCase())
  this.commit('user/updateUserEmail', params.email.trim().toLowerCase())
}

export async function logout(context) {
  this.commit('user/updateUserState', null)
}


export async function getListEvents(context, params) {
  this.commit('user/updateEventListState', null)
  try {
    //let response = await this.$axios.get('user/events')
    let response = await this.$api('GET', 'user/events')
    this.commit('user/updateEventListState', response)
  } catch (e) {}
}

export async function putRgpd(context, params) {
  /*let response = await this.$axios.put('user', {
    reject_rgpd: params,
  })*/

  let response = await this.$api('PUT', 'user', {
    body: {
      reject_rgpd: params,
    }
  })

}
