export async function getEntries(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/entries')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/entries')
}

export async function getEntriesXCFences(context, params) {
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/eventing/xc-fence-entries')
}

export async function getEntriesPaperless(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/paperless/' + params.position)
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/paperless/' + params.position)
}

export async function getSettings(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/settings')
  let response = await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/settings')

  this.commit('show/updateSettingsState', null)
  this.commit('show/updateSettingsState', response)
}

export async function getTimetable(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/timetable')
  let response = await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/timetable')

  this.commit('show/updateTimetableState', null)
  this.commit('show/updateTimetableState', response)
}

export async function getSettingsDressageTest(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/settings/dressage')

  try {
    return await this.$api('GET', 'event/' + params.event_id + '/show/' + params.show_id + '/settings/dressage')
  }
  catch (e) {
    throw e.message
  }
}

export async function getSponsors(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/settings/sponsors')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/settings/sponsors')
}

export async function deleteSponsors(context, params) {
  //let response = await this.$axios.delete('event/'+ params.event_id + '/show/' + params.show_id + '/settings/sponsors/'+params.sponsors_id)
  await this.$api('DELETE', 'event/'+ params.event_id + '/show/' + params.show_id + '/settings/sponsors/'+params.sponsors_id)

  return true
}

export async function getCre(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/cre')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/cre')
}

export async function getCde(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/cde')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/cde')
}


// /event/:id/show/:number/settings/jumping
export async function putSettingsJumping(context, params) {
  //let response = await this.$axios.put('event/' + params.event_id + '/show/' + params.show_id + '/settings/jumping', params)
  await this.$api('PUT', 'event/' + params.event_id + '/show/' + params.show_id + '/settings/jumping', {body: params})
}

// /event/:id/show/:number/settings
export async function putSettings(context, params) {
  //let response = await this.$axios.put('event/' + params.event.id + '/show/' + params.number + '/settings', params)
  await this.$api('PUT', 'event/' + params.event.id + '/show/' + params.number + '/settings', {body: params})
}



// get ranking
export async function getRanking(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/ranking')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/ranking')
}

export async function getRankingLive(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/rank')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/rank')
}


export async function getRankingRider(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/ranking/' + params.bib)
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/ranking/' + params.bib)
}

export async function getRankingRiderGraph(context, params) {
  //let response = await this.$axios.get('event/'+ params.event_id + '/show/' + params.show_id + '/ranking/' + params.bib + '/graph')
  return await this.$api('GET', 'event/'+ params.event_id + '/show/' + params.show_id + '/ranking/' + params.bib + '/graph')

}
