
const routes = [

  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Home', component: () => import(/* webpackChunkName: "group-events" */ 'pages/Index.vue') },
    ]
  },
  {
    path: '/health',
    component: () => import('layouts/ScreenLayout.vue'),
    children: [
      { path: '', name: 'Health', component: () => import(/* webpackChunkName: "group-health" */ 'pages/Health.vue') },
    ]
  },
  {
    path: '/about',
    component: () => import('layouts/ScreenLayout.vue'),
    children: [
      { path: '', name: 'About', component: () => import(/* webpackChunkName: "group-about" */ 'pages/About.vue') },
    ]
  },
  {
    path: '/user',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'me', name: 'UserMe', component: () => import(/* webpackChunkName: "group-user" */ 'pages/UserMe.vue') },
      { path: 'entity', name: 'UserEntity', component: () => import(/* webpackChunkName: "group-user" */ 'pages/UserEntity.vue') },
      { path: 'entries', name: 'UserEntries', component: () => import(/* webpackChunkName: "group-user" */ 'pages/UserEntries.vue') }
    ]
  },
  {
    path: '/events',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Events', component: () => import(/* webpackChunkName: "group-events" */ 'pages/Events.vue') },
      { path: 'new', name: 'EventsNew', component: () => import(/* webpackChunkName: "group-events" */ 'pages/EventsNew.vue') },
    ]
  },
  {
    path: '/event/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: ':event_id/', name: 'Event', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Event.vue') },
      { path: ':event_id/monitor', name: 'EventMonitor', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EventMonitor.vue') },
      { path: ':event_id/settings', name: 'EventSettings', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EventSettings.vue') },
    ]
  },
  {
    path: '/event/:event_id/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'show/:number/entries', name: 'Show', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Show.vue') },
      { path: 'show/:number/settings', name: 'ShowSettings', component: () => import(/* webpackChunkName: "group-event" */ 'pages/ShowSettings.vue') },
      { path: 'show/:number/listing', name: 'ListingShow', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Listing.vue') },
      { path: 'show/:number/xc-monitor', name: 'XcMonitor', component: () => import(/* webpackChunkName: "xc-monitor" */ 'pages/XcMonitor.vue') },
      ]
  },
  {
    path: '/event/:event_id/',
    component: () => import('layouts/PaperlessLayout.vue'),
    children: [
      { path: 'show/:number/paperless', name: 'Paperless', component: () => import(/* webpackChunkName: "paperless" */ 'pages/Paperless.vue') },
      { path: 'show/:number/xc-form', name: 'XcForm', component: () => import(/* webpackChunkName: "xc-form" */ 'pages/XcForm.vue') },
    ]
  },
  {
    path: '/event/:event_id/show/:number/rider/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: ':rider_id/dressage', name: 'EntryDressage', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryDressage.vue') },
      { path: ':rider_id/hunter', name: 'EntryHunter', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryHunter.vue') },
      { path: ':rider_id/jumping', name: 'EntryJumping', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryJumping.vue') },
      { path: ':rider_id/jumpingSJ/:manche?', name: 'EntryJumpingSJ', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryJumpingSJ.vue') },
      { path: ':rider_id/eventing', name: 'EntryEventing', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryEventing.vue') },
      { path: ':rider_id/derby', name: 'EntryDerby', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryDerby.vue') },
      { path: ':rider_id/crossindoor', name: 'EntryCrossIndoor', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryCrossIndoor.vue') },
      { path: ':rider_id/swap-rider', name: 'EntrySwapRider', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntrySwapRider.vue') },
      { path: ':rider_id/swap-horse', name: 'EntrySwapHorse', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntrySwapHorse.vue') },
      { path: ':rider_id/sanction-horse', name: 'EntrySanctionHorse', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntrySanctionHorse.vue') },
      { path: ':rider_id/edit', name: 'EntryEditRider', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryEditRider.vue') },
      { path: 'add', name: 'EntryAddRider', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryAddRider.vue') },
      { path: 'numbering', name: 'EntryNumberingRider', component: () => import(/* webpackChunkName: "group-event" */ 'pages/EntryNumberingRider.vue') },
    ]
  },
  {
    path: '/event/:event_id/timetable',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Timetable', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Timetable.vue') },
      { path: ':timetable_id', name: 'TimetableManage', component: () => import(/* webpackChunkName: "group-event" */ 'pages/TimetableManage.vue') },
      { path: ':timetable_id/show/:show_number', name: 'TimetableManageShow', component: () => import(/* webpackChunkName: "group-event" */ 'pages/TimetableManage.vue') },
    ]
  },
  {
    path: '/event/:event_id/documents',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Documents', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Documents.vue') },
    ]
  },
  {
    path: '/event/:event_id/listing',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Listing', component: () => import(/* webpackChunkName: "group-event" */ 'pages/Listing.vue') },
    ]
  },
  {
    path: '/admin/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'organizers/', name: 'AdminOrganizerList', component: () => import(/* webpackChunkName: "group-admin" */ 'pages/AdminOrganizerList.vue') },
      { path: 'prestataires/', name: 'AdminPrestataireList', component: () => import(/* webpackChunkName: "group-admin" */ 'pages/AdminPrestataireList.vue') },
      { path: 'riders/', name: 'AdminRiderList', component: () => import(/* webpackChunkName: "group-admin" */ 'pages/AdminRiderList.vue') },
    ]
  },
  {
    path: '/tools/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'timer/', name: 'Timer', component: () => import(/* webpackChunkName: "group-chrono" */ 'pages/Timer.vue') },
    ]
  },
  {
    path: '/stats/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'billing', name: 'StatsBilling', component: () => import(/* webpackChunkName: "group-stats" */ 'pages/StatsBilling.vue') },
      { path: 'map', name: 'StatsMap', component: () => import(/* webpackChunkName: "group-stats" */ 'pages/StatsMap.vue') },
      { path: 'staffing', name: 'StatsStaffing', component: () => import(/* webpackChunkName: "group-stats" */ 'pages/StatsStaffing.vue') },
    ]
  },
  {
    path: '/screen/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'config', name: 'ScreenConfig', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenConfig.vue') },
      { path: 'led', name: 'ScreenLED', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenLed.vue') },
      { path: 'led/clock', name: 'ScreenLEDClock', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenLedClock.vue') },
    ]
  },
  {
    path: '/screen/',
    component: () => import('layouts/ScreenLayout.vue'),
    children: [
      { path: 'chromecast', name: 'ScreenChromecast', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenChromecast.vue') },
      { path: 'remote/:template/:code?', name: 'ScreenRemote', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenRemote.vue') },
      { path: 'speaker/cso/:code?', name: 'ScreenSpeaker', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenSpeaker.vue') },
      { path: 'speaker/dressage/:code?', name: 'ScreenSpeakerDressage', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenSpeakerDressage.vue') },
      { path: 'speaker/hunter/:code?', name: 'ScreenSpeakerHunter', component: () => import(/* webpackChunkName: "group-screen" */ 'pages/ScreenSpeakerHunter.vue') },
    ]
  },
  {
    path: '/challenges',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'Challenges', component: () => import(/* webpackChunkName: "group-challenge" */ 'pages/ChallengesList.vue') },
      { path: 'new', name: 'ChallengesNew', component: () => import(/* webpackChunkName: "group-challenge" */ 'pages/ChallengesNew.vue') },
    ]
  },
  {
    path: '/challenge/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: ':id/', name: 'Challenge', component: () => import(/* webpackChunkName: "group-challenge" */ 'pages/Challenge.vue') },
      { path: ':id/manche/:number', name: 'ChallengeManche', component: () => import(/* webpackChunkName: "group-challenge" */ 'pages/ChallengeManche.vue') },
    ]
  },
  {
    path: '/circuit/',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', name: 'ChallengesPublic', component: () => import('pages/ChallengesList.vue') },
      { path: ':id/ranking/', name: 'ChallengeRanking', component: () => import('pages/ChallengeRanking.vue') },
    ]
  },
  {
    path: '/live/',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', name: 'LiveEvents', component: () => import(/* webpackChunkName: "group-live-home" */ 'pages/live/LiveEvents.vue') },
      { path: 'event/:event_id/show/:number/ranking/:bib', name: 'LiveRankingRider', component: () => import(/* webpackChunkName: "group-live" */ 'pages/live/LiveRankingRider.vue') },
      { path: 'event/:event_id/show/:number/timetable/', name: 'LiveTimetable', component: () => import(/* webpackChunkName: "group-live" */ 'pages/live/LiveTimetable.vue') },
      { path: 'event/:event_id/documents', name: 'LiveDocuments', component: () => import(/* webpackChunkName: "group-live" */ 'pages/live/LiveDocuments.vue') },
      { path: 'event/:event_id/', name: 'LiveEvent', component: () => import(/* webpackChunkName: "group-live" */ 'pages/live/LiveEvent.vue') },
      { path: 'event/:event_id/show/:number/ranking/', name: 'LiveRanking', component: () => import(/* webpackChunkName: "group-live" */ 'pages/live/LiveRanking.vue') },
    ]
  },
  {
    path: '/auth/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: 'login', name: 'AuthLogin', component: () => import(/* webpackChunkName: "group-auth" */ 'pages/auth/AuthLogin.vue') },
      { path: 'login/fallback', name: 'AuthLoginFallback', component: () => import(/* webpackChunkName: "group-auth" */ 'pages/auth/AuthLoginFallback.vue') },
      { path: 'login/equibel', name: 'AuthLoginEquibel', component: () => import(/* webpackChunkName: "group-auth" */ 'pages/auth/AuthLoginEquibel.vue') },
      ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
