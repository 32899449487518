export default {
  "auth": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rug"])},
    "equibel": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatie"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen Equibel-account?"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding via Equibel"])}
    },
    "fallback": {
      "getlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang een link"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificatie"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen account?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het nog eens"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een wedstrijd beheren was nog nooit zo eenvoudig"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])}
  },
  "challenges": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranglijst"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer de uitdaging"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "table": {
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "manches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "events": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om toegang te krijgen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een wedstrijd toe"])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt niet het recht om een wedstrijd te creëren"])},
    "bill": {
      "billed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgereikte factuur"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gefactureerd"])},
      "not-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de wacht"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturering aan het einde van de maand"])}
    },
    "federation": {
      "be-equibel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FRBSE (Equibel Nationaal)"])},
      "be-lewb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallonië Brussels Equestrian League (gemeenschap LEWB)"])},
      "fei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEI"])},
      "ffe-compet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE Compet"])},
      "ffe-sif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE Sif"])}
    },
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatie in uitvoering"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creatie voltooid"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer de concurrentie"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg een wedstrijd toe"])}
    },
    "status": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archief"])},
      "calendrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepland"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
    },
    "table": {
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturering"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin datum"])},
      "federation_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "ffe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federatie"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisator"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestand"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriendelijk"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn wedstrijden"])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing"])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruis binnen"])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumping"])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby-kruis"])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby-eventing"])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressuur"])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equifun"])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jager"])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pony-spellen"])}
    }
  },
  "footer": {
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedstrijden"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De meest innovatieve oplossing voor computerverwerking van paardensportwedstrijden. Zonder installatie, direct beschikbaar."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentatie"])},
    "informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talen"])},
    "see-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk de resultaten"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service status"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek Horses & Competities"])}
  },
  "home": {
    "calendar": {
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom"])}
  },
  "live": {
    "documents": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadplegen"])},
      "error": {
        "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan wedstrijdmateriaal niet ophalen"])}
      },
      "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedstrijddocumenten"])},
      "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen documenten"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["georganiseerd door"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten"])}
    },
    "event": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze wedstrijd is gearchiveerd, niet langer beschikbaar op ons platform. Vind de resultaten direct op de website van de Federatie."])},
      "bareme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schub"])},
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze wedstrijd is gepland, de organisator heeft deze nog niet geïmporteerd."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze wedstrijd is geannuleerd"])},
      "date-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van"])},
      "date-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind alle informatie over dit evenement"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over de wedstrijd"])},
      "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewijd"])},
      "error": {
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen informatie ophalen van deze wedstrijd"])}
      },
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evenement"])},
      "no-shows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tests"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["georganiseerd door"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadplegen"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startlijst"])}
    },
    "events": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugkeer"])},
      "error": {
        "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan wedstrijden niet ophalen"])}
      },
      "filter": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "date-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIT JAAR"])},
        "date-current-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze week"])},
        "date-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige week"])},
        "date-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
        "discipline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])},
        "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen resultaten"])},
        "organisateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisator"])}
      },
      "table": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerd"])},
        "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadplegen"])},
        "title-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenissen"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten van de wedstrijd"])}
    },
    "ranking": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "bib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slabbetje"])},
      "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club"])},
      "crossindoor": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale tijd"])}
      },
      "dressage": {
        "long_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressuur"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DR"])}
      },
      "errors": {
        "fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan sortering niet ophalen"])}
      },
      "eventing": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale tijd XC"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XC"])}
      },
      "juge": {
        "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In B"])},
        "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In C"])},
        "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In E"])},
        "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In H"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In M"])}
      },
      "juges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechters"])},
      "jumping": {
        "time_phase_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd fase 1"])},
        "time_phase_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd fase 2"])},
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale tijd SJ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SJ"])}
      },
      "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natie"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["georganiseerd door"])},
      "penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pen"])},
      "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schub"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
      "rider-horse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruiter / Paard"])},
      "show_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papieren nr."])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
      "tetiere_inter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startlijst"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])}
    },
    "timetable": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
      "errors": {
        "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan het schema van deze wedstrijd niet ophalen"])}
      },
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste update"])},
      "no-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De organisator heeft geen schema's gepubliceerd, heb je in de documenten gekeken?"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdschema Test Nr."])}
    }
  },
  "menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn rekeningen"])},
    "challenges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitdagingen"])},
    "circuits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rondleidingen"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn wedstrijden"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmelden"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzorgingsgebied"])},
    "organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatoren"])},
    "prestataires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbieders"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultaten"])},
    "riders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renners"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschrijving"])},
    "staffing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personeelsbezetting"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiek"])}
  },
  "organizers": {
    "table": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "prestataires": {
    "table": {
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federatie"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "riders": {
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federatie"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "screen": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "type": {
      "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "alpha_v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "hl950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag HL950"])},
      "hl980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "system": {
    "copy": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet kopiëren naar klembord"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd naar klembord"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om te kopiëren"])}
    },
    "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat meer zien"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lading..."])},
    "nav": {
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omleiding..."])}
    },
    "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we hebben geen resultaten gevonden"])}
  },
  "timer": {
    "device": {
      "alge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "alge_s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "fds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "racetime_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "tag_minitimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "timetable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "user": {
    "entity": {
      "form": {
        "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres voor facturering"])},
        "email": {
          "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u facturen wilt ontvangen via een andere e-mail dan ", _interpolate(_named("email"))])}
        },
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behendigheid"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
      },
      "subtitle": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuurgegevens"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn organisatie"])}
    },
    "ffe": {
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige informatie is niet wijzigbaar, het komt van het FFE-computersysteem"])},
      "restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om uw gegevens te wijzigen gaat u naar de FFE website"])}
    },
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "forbiden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U mag deze actie niet uitvoeren"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogenblikje..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgeslagen wijzigingen"])}
    },
    "role": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatenmanager"])},
      "chef_piste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailbeheerder"])},
      "chrono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdwaarnemer"])},
      "commissaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoor maarschalk"])},
      "juge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechter"])},
      "paddock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paddock-commissaris"])},
      "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreker"])}
    },
    "subtitle": {
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persoonlijke informatie"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "type": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisator"])},
      "prestataire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbieder"])},
      "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruiter"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])}
    }
  }
}