import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

// import example from './module-example'
import user from './user'
import entity from './entity'
import event from './event'
import challenge from './challenge'
import show from './show'
import organizer from './organizer'
import prestataire from './prestataire'
import rider from './rider'
import timetable from './timetable'
import stats from './stats'
import telegram from './telegram'
import auth from './auth'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function ( { ssrContext } ) {
  const Store = createStore({
    modules: {
      user,
      entity,
      event,
      challenge,
      show,
      organizer,
      prestataire,
      rider,
      timetable,
      stats,
      telegram,
      auth,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
