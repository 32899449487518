import { boot } from 'quasar/wrappers'
import {Cookies, Quasar} from "quasar";

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async ( { ssrContext }) => {
  // something to do
  const langIso = 'fr'  // ... some logic to determine it (use Cookies Plugin?)

  try {
    await import(
      /* webpackInclude: /(fr|en-US)\.js$/ */
    'quasar/lang/' + langIso
      )
      .then(lang => {
        Quasar.lang.set(lang.default, ssrContext)
      })
  }
  catch (err) {
    // Requested Quasar Language Pack does not exist,
    // let's not break the app, so catching error
  }
})
