
export function eventGetter (state) {
  return state.event
}

export function eventListGetter (state) {
  return state.events
}

export function documentsGetter (state) {
  return state.documents
}

export function providersGetter (state) {
  return state.providers
}
