import { boot } from 'quasar/wrappers'
import { io } from 'socket.io-client';

export default boot(({ app }) => {

  const socket = io('socket.horses-and-competitions.com:443', {
    autoConnect: false,
    transports: ["websocket", "polling"]
  })

  socket.on("connect_error", () => {
    // revert to classic upgrade
    socket.io.opts.transports = ["polling", "websocket"];
  });

  app.config.globalProperties.$socket = socket;
})
