export async function getListChallenges(context, params) {

  const uriParams = new URLSearchParams();

  if(params && params.type)
    uriParams.append('type[]', params.type);

  if (params && params.date_week)
    uriParams.append("date[week]", 'true');

  if (params && params.date_today)
    uriParams.append("date[today]", 'true');

  if (params && params.date_last_week)
    uriParams.append("date[last_week]", 'true');

  if (params && params.date_start)
    uriParams.append("date[start]", params.date_start);

  if (params && params.date_end)
    uriParams.append("date[end]", params.date_end);

  if (params && params.me)
    uriParams.append("owner", 'me');

  if (params && params.organizer)
    uriParams.append("organizers[]", params.organizer);

  this.commit('challenge/updateChallengeListState', null)
  try {
    //let response = await this.$axios.get('challenges', {params: uriParams})
    let response = await this.$api('GET', 'challenges?' + new URLSearchParams(uriParams))

    this.commit('challenge/updateChallengeListState', response)
  } catch (e) {}
}

export async function getChallenge(context, id) {

  //let response = await this.$axios.get('challenge/' + id)
  return await this.$api('GET', 'challenge/' + id)
}

export async function getChallengeRanking(context, id) {

  //let response = await this.$axios.get('challenge/' + id + '/ranking')
  return await this.$api('GET', 'challenge/' + id + '/ranking')
}

export async function getChallengeManche(context, params) {

  //let response = await this.$axios.get('challenge/' + params.id + '/manche/' + params.manche_id)
  return await this.$api('GET', 'challenge/' + params.id + '/manche/' + params.manche_id)
}

export async function getChallengeMancheResult(context, params) {

  //let response = await this.$axios.get('challenge/' + params.id + '/manche/' + params.manche_id + '/result')
  return await this.$api('GET', 'challenge/' + params.id + '/manche/' + params.manche_id + '/result')

}

export async function addChallenge(context, params) {
  //let response = await this.$axios.post('challenge', params)
  let response = await this.$api('POST', 'challenge', {body:params})

}

export async function addManche(context, params) {
  //let response = await this.$axios.post('challenge/' + params.challenge_id, params)
  let response = await this.$api('POST', 'challenge/' + params.challenge_id, {body:params})
}

export async function processManche(context, params) {
  //let response = await this.$axios.post('challenge/' + params.challenge_id + '/manche/' + params.manche_id , params.selected)
  let response = await this.$api('POST', 'challenge/' + params.challenge_id + '/manche/' + params.manche_id, {body:params.selected})
}

export async function saveManche(context, params) {
  //let response = await this.$axios.post('challenge/' + params.challenge_id + '/manche/' + params.manche_id + '/result' , params.result)
  let response = await this.$api('POST', 'challenge/' + params.challenge_id + '/manche/' + params.manche_id + '/result', {body:params.result})

}
