export async function getListPrestataires(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.with_auth_link)
    uriParams.append('with_auth_link', params.with_auth_link);


  this.commit('prestataire/updatePrestataireListState', null)
  try {
    //let response = await this.$axios.get('prestataires', {params: uriParams})
    let response = await this.$api('GET', 'prestataires?' + new URLSearchParams(uriParams))
    this.commit('prestataire/updatePrestataireListState', response)
  } catch (e) {}
}
