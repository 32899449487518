
export function updateBillingState (state, data) {
  state.billing = data
}

export function updateBillingEntitiesState (state, data) {
  state.billingsEntities = data
}


export function updateMapState (state, data) {
  state.map = data
}


export function updateEventsState (state, data) {
  state.events = data
}
