
export async function getEntity(context) {
  this.commit('entity/updateEntityState', null)
  try {
    //let response = await this.$axios.get('user/entity')
    let response = await this.$api('GET', 'user/entity')
    this.commit('entity/updateEntityState', response)
  } catch (e) {}
}

export async function putEntity(context, params) {
  try {
    /*let response = await this.$axios.put('user/entity', {
      billing_email: params.billing_email.trim().toLowerCase(),
    })*/

    let response = await this.$api('PUT', 'user/entity', {
      body:{
        billing_email: params.billing_email.trim().toLowerCase(),
      }
    })

  } catch (e) {}
}
