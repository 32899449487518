export async function getTimetableList(context, params) {

  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/timetable/list/' + params.show_id)
    return await this.$api('GET', 'event/' + params.event_id + '/timetable/list/' + params.show_id)
  } catch (e) {}
}

export async function getTimetableArray(context, params) {

  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/timetable/list/')
    return await this.$api('GET', 'event/' + params.event_id + '/timetable/list/')
  } catch (e) {}
}

export async function getTimetableCalendar(context, params) {

  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/timetable/calendar')
    return await this.$api('GET', 'event/' + params.event_id + '/timetable/calendar')
  } catch (e) {}
}

export async function getTimetable(context, params) {

  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/timetable/' + params.timetable_id)
    return await this.$api('GET', 'event/' + params.event_id + '/timetable/' + params.timetable_id)
  } catch (e) {}
}

export async function getTimetableListEntries(context, params) {

  const uriParams = new URLSearchParams();

  uriParams.append('inverse', params.ranking ?? false);

  uriParams.append('no_hours', params.notimetable ?? false);

  uriParams.append('no_indice', params.allowed ?? false);

  uriParams.append('order_inverse', params.order_inverse ?? false);

  uriParams.append('order_tetiere', params.order_tetiere ?? false);

  if (params && params.allowed_time) {
    if (params.allowed_time === '') {
      uriParams.append('allowed_time','60');
    }
    else
      uriParams.append('allowed_time',params.allowed_time);
  }
  else
    uriParams.append('allowed_time','60');

  if (params && params.show_id)
    uriParams.append('show_id', params.show_id);



  this.commit('timetable/updateTimetableEntriesListState', null)
  try {
    //let response = await this.$axios.get('event/' + params.event_id + '/timetable/' + params.timetable_id + '/list', {params: uriParams})
    let response = await this.$api('GET', 'event/' + params.event_id + '/timetable/' + params.timetable_id + '/list?' + new URLSearchParams(uriParams))

    this.commit('timetable/updateTimetableEntriesListState', response)
  } catch (e) {}
}

export async function save(context, params) {
  // let response = await this.$axios.post('event/' + params.event_id + '/timetable/' + params.timetable_id + '/list', params)
  return await this.$api('POST', 'event/' + params.event_id + '/timetable/' + params.timetable_id + '/list', {body:params})
}

export async function editZone(context, params) {

  let data = {
    published : null,
    name : null
  }

  if (params.published !== null) // !== null
    data.published = params.published

  if (params.name) // !== null
    data.name = params.name

  try {
    //let response = await this.$axios.put('event/' + params.event_id + '/timetable/' + params.zone_id, data)
    let response = await this.$api('PUT', 'event/' + params.event_id + '/timetable/' + params.zone_id, {body:data})
  }
  catch (e) {
      throw e.message
  }

  return true
}

export async function deleteZone(context, params) {

  try {
    //let response = await this.$axios.delete('event/' + params.event_id + '/timetable/' + params.zone_id)
    let response = await this.$api('DELETE', 'event/' + params.event_id + '/timetable/' + params.zone_id)
  }
  catch (e) {
    throw e.message
  }

  return true
}

export async function addZone(context, params) {

  let data = {
    name : null,
    type : 'timetable',
    day: null
  }

  if (params.published !== null) // !== null
    data.published = params.published

  if (params.name !== null) // !== null
    data.name = params.name

  if (params.type !== null) // !== null
    data.type = params.type.value

  if (params.type !== null) // !== null
    data.day = params.day

  try {
    //let response = await this.$axios.post('event/'+ params.event_id + '/timetable/', data)
    let response = await this.$api('POST', 'event/'+ params.event_id + '/timetable/', {body:data})
  }
  catch (e) {
    throw e.message
  }

  return true
}
