
export function updateEventState (state, data) {
  state.event = data
}

export function updateEventListState (state, data) {
  state.events = data
}


export function updateDocumentsState (state, data) {
  state.documents = data
}

export function updateProvidersState (state, data) {
  state.providers = data
}
