export default {
  "auth": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retour"])},
    "equibel": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte Equibel ?"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion via Equibel"])}
    },
    "fallback": {
      "getlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir un lien"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de compte ?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer un concours n'a jamais été aussi simple"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
  },
  "challenges": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classement"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un challenge"])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas le droit de créer un challenge"])},
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création en cours"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création terminée"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le challenge"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un challenge"])}
    },
    "table": {
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "manches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nb Manches"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisateur"])},
      "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithme"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenges"])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCE"])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Indoor"])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSO"])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Cross"])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Eventing"])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equifun"])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pony Games"])}
    }
  },
  "events": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un concours"])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas le droit de créer un concours"])},
    "bill": {
      "billed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture émise"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non facturé"])},
      "not-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation en fin de mois"])}
    },
    "federation": {
      "be-equibel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F.R.B.S.E (Equibel National)"])},
      "be-lewb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligue Equestre Wallonie Bruxelles (LEWB communautaire)"])},
      "fei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEI"])},
      "ffe-compet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE Compet"])},
      "ffe-sif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIF"])}
    },
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création en cours"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création terminée"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le concours"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un concours"])}
    },
    "status": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivé"])},
      "calendrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifié"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])}
    },
    "table": {
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "federation_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
      "ffe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fédération"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisateur"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes concours"])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCE"])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Indoor"])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSO"])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Cross"])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Eventing"])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equifun"])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pony Games"])}
    }
  },
  "footer": {
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétitions"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solution la plus innovante pour le traitement informatique des compétitions équestres. Sans installation, disponible immédiatement."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
    "see-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les résultats"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État des services"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez Horses & Competitions"])}
  },
  "home": {
    "calendar": {
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
  },
  "live": {
    "documents": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter"])},
      "error": {
        "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les documents de ce concours"])}
      },
      "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents du concours"])},
      "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisé par"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])}
    },
    "event": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce concours est archivé, il n'est plus disponible sur notre plateforme. Retrouvez les résultats directement sur le site de la Fédération."])},
      "bareme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barème"])},
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce concours est planifié, l'organisateur ne l'a pas encore importé."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce concours est annulé"])},
      "date-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "date-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez toutes les informations sur cet évènement"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations concours"])},
      "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagés"])},
      "error": {
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les informations de ce concours"])}
      },
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concours"])},
      "no-shows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune épreuves"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisé par"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipes"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start list"])}
    },
    "events": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "error": {
        "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les concours"])}
      },
      "filter": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "date-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année"])},
        "date-current-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
        "date-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine dernière"])},
        "date-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
        "discipline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])},
        "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultats"])},
        "organisateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisateur"])}
      },
      "table": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
        "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter"])},
        "title-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évènements"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats des concours"])}
    },
    "ranking": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "bib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doss."])},
      "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club"])},
      "crossindoor": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps optimal"])}
      },
      "dressage": {
        "long_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DR"])}
      },
      "errors": {
        "fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer le classement"])}
      },
      "eventing": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps optimal Cross"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross"])}
      },
      "juge": {
        "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En B"])},
        "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En C"])},
        "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En E"])},
        "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En H"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En M"])}
      },
      "juges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juges"])},
      "jumping": {
        "time_phase_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps phase 1"])},
        "time_phase_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps phase 2"])},
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps optimal CSO"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSO"])}
      },
      "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nation"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisé par"])},
      "penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pen"])},
      "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barème"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rang"])},
      "rider-horse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cavalier / Cheval"])},
      "show_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épreuve N°"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe"])},
      "tetiere_inter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Têtière"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start list"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "timetable": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "errors": {
        "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer le planning de ce concours"])}
      },
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
      "no-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'organisateur n'a pas publié d'horaires, avez-vous regardé dans les documents ?"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires Épreuve N°"])}
    }
  },
  "menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes factures"])},
    "challenges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenges"])},
    "circuits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuits"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes concours"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone chalandise"])},
    "organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisateurs"])},
    "prestataires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
    "riders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cavaliers"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "staffing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staffing"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])}
  },
  "organizers": {
    "table": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email utilisateur"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
      "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les organisateurs"])}
  },
  "prestataires": {
    "table": {
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fédération"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prestataires"])}
  },
  "riders": {
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fédération"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Cavaliers"])}
  },
  "screen": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écran"])},
    "type": {
      "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générique (protocole ALPHA)"])},
      "alpha_v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générique (protocole ALPHA 3.0)"])},
      "hl950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag HL950"])},
      "hl980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag HL980"])}
    }
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
  },
  "stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])}
  },
  "system": {
    "copy": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier dans le presse papier"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié dans le presse papier"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour copier"])}
    },
    "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher davantage"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "nav": {
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection..."])}
    },
    "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous n'avons trouvé aucun résultat"])}
  },
  "timer": {
    "device": {
      "alge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALGE-TIMING - Timy v2 / Timy v3 (mode PC-Timer)"])},
      "alge_s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALGE-TIMING - S4 (beta)"])},
      "fds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FDS Timing Solutions (mode TAG)"])},
      "racetime_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MICROGATE - RACETIME 2"])},
      "tag_minitimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Heuer - Minitimer"])}
    },
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion avec votre chronomètre a été perdue"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronomètre"])}
  },
  "timetable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification"])}
  },
  "user": {
    "entity": {
      "form": {
        "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail de facturation"])},
        "email": {
          "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["si vous souhaitez recevoir les factures sur un autre email que ", _interpolate(_named("email"))])}
        },
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
      },
      "subtitle": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de facturation"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Organisation"])}
    },
    "ffe": {
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines informations ne sont pas modifiable, elles proviennent du système informatique FFE"])},
      "restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour modifier vos informations rendez-vous sur le site de la FFE"])}
    },
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "forbiden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à faire cette action"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un instant..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications sauvegardées"])}
    },
    "role": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire de résultats"])},
      "chef_piste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef de pistes"])},
      "chrono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronométreur"])},
      "commissaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux obstacles"])},
      "juge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juge"])},
      "paddock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire au paddock"])},
      "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])}
    },
    "subtitle": {
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "type": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisateur"])},
      "prestataire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataire"])},
      "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cavalier"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
    }
  }
}