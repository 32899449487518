import { boot } from 'quasar/wrappers'
import {Loading} from "quasar";

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

export default boot( ({ app, store, ssrContext }) => {

  const clientAPi = (method = 'GET', endpoint, {body, ...customConfig} = {}) => {
    const headers = {
      'accept': 'application/json, text/plain, */*'
    }

    // force origin to cloudflare on ssr
    if (ssrContext !== null && ssrContext !== undefined) {
      headers.Origin = 'https://app.horses-and-competitions.com'
    }

    const config = {
      method: method,
      credentials: "include",
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }
    if (body) {
      config.body = JSON.stringify(body)
    }

    let base_url = process.env.VUE_APP_API_URL + 'fr/api/v1/'

    if (config.baseURL)
      base_url = config.baseURL

    return fetch(`${base_url}${endpoint}`, config)
      .then(async response => {

        if (response.status === 401) {
          Loading.show({
            message: 'Vous n\'êtes pas connecté, redirection vers la page de connexion...'
          })
          store.dispatch("user/logout").then(() => {
            window.location.href = process.env.VUE_APP_LINK_LOGIN
          })
          return
        }
        if (response.status === 403) {
          throw Error("Access refused")
        }
        if (response.status === 404) {
          return Promise.reject(new Error('Not Found'))
        }
        if (response.ok) {
          if (response.status === 200) {

            if (config.responseType && config.responseType === 'blob') {
              return await response.blob()
            }
            else if (config.responseType && config.responseType === 'text')
              return await response.text()
            else {
              return await response.json()
              }
          }
        } else {
          const errorMessage = await response.text() + ' (' + response.status + ')'
          return Promise.reject(new Error(errorMessage))
        }
      })
  }


  //app.config.globalProperties.$axios = client
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = clientAPi
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  store.$api = clientAPi
})


