
export function updateUserState (state, data) {
  state.user = data
}

export function updateUserFirstname (state, data) {
  state.user.firstname = data
}

export function updateUserLastname (state, data) {
  state.user.lastname = data
}

export function updateUserEmail (state, data) {
  state.user.email = data
}

export function updateEventListState (state, data) {
  state.events = data
}

export function updateEventListOfficialState (state, data) {
  state.eventsOfficial = data
}

