
export function billingGetter (state) {
  return state.billing
}

export function billingEntitiesGetter (state) {
  return state.billingsEntities
}

export function mapGetter (state) {
  return state.map
}

export function eventsGetter (state) {
  return state.events
}

