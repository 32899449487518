import {openURL} from "quasar";

export async function getListEvents(context, params) {

  const uriParams = new URLSearchParams();

  if(params && params.type && typeof params.type === 'string') {
      uriParams.append('type[]', params.type);
  }

  else if(params && params.type && Object.keys(params.type).length > 0) {
    for (let i = 0; i < Object.keys(params.type).length; i++) {
      uriParams.append('type[]', params.type[i]);
    }
  }

  if (params && params.federation_number)
    uriParams.append('federation_number', params.federation_number);

  if (params && params.date_week)
    uriParams.append("date[week]", 'true');

  if (params && params.date_today)
    uriParams.append("date[today]", 'true');

  if (params && params.date_last_week)
    uriParams.append("date[last_week]", 'true');

  if (params && params.date_start)
    uriParams.append("date[start]", params.date_start);

  if (params && params.date_end)
    uriParams.append("date[end]", params.date_end);

  if (params && params.me)
    uriParams.append("owner", 'me');


  if (params && params.organizer && (Object.keys(params.organizer).length > 0 || params.organizer.length > 0)) {

    for (let i = 0; i < Object.keys(params.organizer).length; i++) {
      uriParams.append("organizers[]", params.organizer[i]);
    }
  }

  this.commit('event/updateEventListState', null)
  try {
    //let response = await this.$axios.get('events', {params: uriParams})
    let response = await this.$api('GET', 'events?' + new URLSearchParams(uriParams))

    this.commit('event/updateEventListState', response)
  } catch (e) {}
}

export async function getEvent(context, id) {

  try {
    this.commit('event/updateEventState', null)
    //let response = await this.$axios.get('event/' + id)
    let response = await this.$api('GET', 'event/' + id)

    this.commit('event/updateEventState', response)
  }
  catch (e) {
  }
}

export async function getEventWithAdditional(context, id) {

  const uriParams = new URLSearchParams();
  uriParams.append("details", true);

  this.commit('event/updateEventState', null)
  //let response = await this.$axios.get('event/' + id, {params: uriParams})
  let response = await this.$api('GET', 'event/' + id + '?' + new URLSearchParams(uriParams))

  this.commit('event/updateEventState', response)
}

// /event/:id/providers
export async function getProviders(context, id) {

  this.commit('event/updateProvidersState', null)
  //let response = await this.$axios.get('event/' + id + '/providers')
  let response = await this.$api('GET', 'event/' + id + '/providers')

  this.commit('event/updateProvidersState', response)
}

// /event/:id/providers
export async function addProvider(context, params) {

  try {
    //let response = await this.$axios.post('event/' + params.event_id + '/providers', params)
    let response = await this.$api('POST', 'event/' + params.event_id + '/providers', {body:params})
  }
  catch (e) {
    throw e.message
  }
}

export async function importEventFromAPI(context, id) {
  try {
    let response = await this.$api('POST', 'event/' + id)
  }
  catch (e) {
    throw e.message
  }
}

// /event/:id/providers
export async function deleteProvider(context, params) {

  try {
    //let response = await this.$axios.delete('event/' + params.event_id + '/providers/' + params.provider)
    let response = await this.$api('DELETE', 'event/' + params.event_id + '/providers/' + params.provider)
  }
  catch (e) {
    throw e.message
  }
}


// /event/:id/settings
export async function putSettings(context, params) {
  //let response = await this.$axios.put('event/' + params.id + '/settings', params)
  let response = await this.$api('PUT', 'event/' + params.id + '/settings', {body:params})
}

export async function getEventMonitor(context, id) {

  //let response = await this.$axios.get('event/' + id + '/monitor')
  return await this.$api('GET', 'event/' + id + '/monitor')
}

export async function addEvent(context, params) {
  //let response = await this.$axios.post('event', params)
  await this.$api('POST', 'event', {body:params})
}

export async function getDocuments(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.me)
    uriParams.append("owner", 'me');

  this.commit('event/updateDocumentsState', null)

  //let response = await this.$axios.get('event/' + params.id + '/documents', {params: uriParams})
  let response = await this.$api('GET', 'event/' + params.id + '/documents?' + new URLSearchParams(uriParams))

  this.commit('event/updateDocumentsState', response)
}

export async function deleteDocument(context, params) {
  //let response = await this.$axios.delete('event/'+ params.event_id + '/documents/'+params.document_id)
  await this.$api('DELETE', 'event/'+ params.event_id + '/documents/'+params.document_id)

  return true
}

export async function editDocument(context, params) {

  let data = {
    published : null,
    name : null
  }

  if (params.published !== null) // !== null
    data.published = params.published

  if (params.name) // !== null
    data.name = params.name

  //let response = await this.$axios.put('event/'+ params.event_id + '/documents/'+params.document_id, data)
  await this.$api('PUT', 'event/'+ params.event_id + '/documents/'+params.document_id, {body:data})

  return true
}

export async function getListing(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.format)
    uriParams.append("format", params.format);

  if (params && params.orientation)
    uriParams.append("orientation", params.orientation);

  if (params && params.zone_id)
    uriParams.append("zone_id", params.zone_id);

  // option entete
  params.entetes.forEach(function(option, index){

    if (option.value === true)
      uriParams.append(option.name, 'true');
  });

  let response = null

  let lang = 'fr'

  if (params.federation && params.federation === 'fei')
    lang = 'en'

  if (params.show_number === undefined) {
    /*response = await this.$axios.get('event/' + params.id + '/listing', {
      params: uriParams,
      responseType: 'blob',
      baseURL: process.env.VUE_APP_API_URL + lang + '/api/v1/'
    })*/

    response = await this.$api('GET', 'event/' + params.id + '/listing?' + new URLSearchParams(uriParams), {
      responseType: 'blob', baseURL: process.env.VUE_APP_API_URL + lang + '/api/v1/'
    })
  }
  else {
    //response = await this.$axios.get('event/' + params.id + '/show/'+ params.show_number +'/listing', {params: uriParams, responseType: 'blob', baseURL: process.env.VUE_APP_API_URL + lang + '/api/v1/'})

    response = await this.$api('GET', 'event/' + params.id + '/show/'+ params.show_number +'/listing?' + new URLSearchParams(uriParams), {
      responseType: 'blob', baseURL: process.env.VUE_APP_API_URL + lang + '/api/v1/'
    })
  }

  return response
}


export async function getProtocoles(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.timetable_id)
    uriParams.append("timetable_id", params.timetable_id);

  if (params && params.juge_position)
    uriParams.append("juge_position", params.juge_position);

  let response = null

  openURL(process.env.VUE_APP_BACKEND_URL + 'fr/gestion/concours/' + params.id + '/epreuve/'+ params.show_number +'/print-protocoles/?' + uriParams.toString())
  return true
}
