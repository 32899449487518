import { boot } from 'quasar/wrappers'
import { Crisp } from "crisp-sdk-web"

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

export default boot(async ({ app }) => {
  Crisp.configure('2e9a18cf-c153-4f36-acd3-9c944b5c97dc',
    {
      autoload: false,
      safeMode: true,
      sessionMerge: true
    })
})
