import { boot } from 'quasar/wrappers'
import VueGtag from "vue-gtag";
import setOptions from "vue-gtag";

export default boot(async ({ app, router }) => {
  app.use(VueGtag, {
    config: {
      id: "G-JWD69TLJD3",
      params: {
        anonymize_ip: true,
        debug_mode: !process.env.PROD
      }
    }
  }, router)
})
