import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import { Quasar, Cookies } from 'quasar'

function loadLocaleMessages () {
  const locales = require.context('../i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default boot(({ app, ssrContext }) => {

  const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr-FR'
  let locale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr-FR'

  const cookies = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies // otherwise we're on client

  if (cookies.has('user_language'))
    locale = cookies.get('user_language');

  //if (cookies)
  //  cookies.set('user_language', locale, { expires: 365 })

  if (!process.env.SERVER) {
    locale = Quasar.lang.getLocale()

    if (locale !== undefined && locale !== null && !cookies.has('user_language'))
      cookies.set('user_language', locale, { expires: 365, path: '/', secure: true })
  }

  const i18n = createI18n({
    locale: locale,
    fallbackLocale: fallbackLocale,
    messages: loadLocaleMessages(),
    globalInjection: true,
  })

  // Set i18n instance on app
  app.use(i18n)
})
