export default {
  "auth": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "equibel": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Equibel account?"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection via Equibel"])}
    },
    "fallback": {
      "getlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive a link"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
      "no-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing a competition has never been easier"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])}
  },
  "challenges": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a challenge"])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the right to create a challenge"])},
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation in progress"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation completed"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the challenge"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a challenge"])}
    },
    "table": {
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "manches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Sleeves"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])},
      "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
      "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithm"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenges"])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing"])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Indoor"])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumping"])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Cross"])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Eventing"])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equifun"])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pony Games"])}
    }
  },
  "events": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an event"])},
    "add-not-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have the right to create an event"])},
    "bill": {
      "billed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice issued"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not billed"])},
      "not-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hold"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing at the end of the month"])}
    },
    "federation": {
      "be-equibel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F.R.B.S.E (Equibel National)"])},
      "be-lewb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equestrian League Wallonia Brussels (Community LEWB)"])},
      "fei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEI"])},
      "ffe-compet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE Compet"])},
      "ffe-sif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE Sif"])}
    },
    "form": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation in progress"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation completed"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the competition"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an event"])}
    },
    "status": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
      "calendrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])}
    },
    "table": {
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
      "federation_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number"])},
      "ffe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federation"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My events"])},
    "type": {
      "cce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing"])},
      "crossindoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Indoor"])},
      "cso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumping"])},
      "derby_cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Cross"])},
      "derby_eventing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby Eventing"])},
      "dressage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
      "equifun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equifun"])},
      "hunter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
      "pony_games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pony Games"])}
    }
  },
  "footer": {
    "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitions"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most innovative solution for the computer processing of equestrian competitions. Without installation, available immediately."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
    "informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "see-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the results"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of services"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover Horses & Competitions"])}
  },
  "home": {
    "calendar": {
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "live": {
    "documents": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See"])},
      "error": {
        "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve the documents for this competition"])}
      },
      "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition documents"])},
      "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organized by"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])}
    },
    "event": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is archived, it is no longer available on our platform. Find the results directly on the Federation's website."])},
      "bareme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is planned, the organizer has not imported it yet."])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is canceled"])},
      "date-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "date-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find all the information about this event"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event information"])},
      "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
      "error": {
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve information about this event"])}
      },
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "no-shows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No shows"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organized by"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start list"])}
    },
    "events": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "error": {
        "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve events"])}
      },
      "filter": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "date-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year"])},
        "date-current-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
        "date-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
        "date-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "discipline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
        "organisateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])}
      },
      "table": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
        "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
        "title-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition results"])}
    },
    "ranking": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "bib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bib"])},
      "club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club"])},
      "crossindoor": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal time"])}
      },
      "dressage": {
        "long_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DR"])}
      },
      "errors": {
        "fetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve ranking"])}
      },
      "eventing": {
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal time XC"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XC"])}
      },
      "juge": {
        "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In B"])},
        "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In C"])},
        "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In E"])},
        "h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In H"])},
        "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In M"])}
      },
      "juges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judges"])},
      "jumping": {
        "time_phase_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase 1 time"])},
        "time_phase_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase 2 time"])},
        "time_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal time SJ"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SJ"])}
      },
      "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nation"])},
      "organizedby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organized by"])},
      "penality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pen"])},
      "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
      "rider-horse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rider / Horse"])},
      "show_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test N °"])},
      "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
      "tetiere_inter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start list"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "timetable": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "errors": {
        "timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to retrieve the schedule for this event"])}
      },
      "last-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Update"])},
      "no-timetable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organizer has not published a timetable, have you looked in the documents ?"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable show N°"])}
    }
  },
  "menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My bills"])},
    "challenges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenges"])},
    "circuits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuits"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My events"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catchment area"])},
    "organizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizers"])},
    "prestataires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "riders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riders"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "staffing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staffing"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])}
  },
  "organizers": {
    "table": {
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Email"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Reason"])},
      "zip_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizers"])}
  },
  "prestataires": {
    "table": {
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federation"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])}
  },
  "riders": {
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail"])},
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federation"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riders"])}
  },
  "screen": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screen"])},
    "type": {
      "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic (ALPHA protocol)"])},
      "alpha_v3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic (ALPHA 3.0 protocol)"])},
      "hl950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag HL950"])},
      "hl980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag HL980"])}
    }
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])}
  },
  "system": {
    "copy": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy to clipboard"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied To Clipboard"])},
      "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to copy"])}
    },
    "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "nav": {
      "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect..."])}
    },
    "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we couldn't find any results"])}
  },
  "timer": {
    "device": {
      "alge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALGE-TIMING - Timy v2 / Timy v3 (mode PC-Timer)"])},
      "alge_s4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALGE-TIMING - S4 (beta)"])},
      "fds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FDS Timing Solutions (TAG mode)"])},
      "racetime_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MICROGATE - RACETIME 2"])},
      "tag_minitimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Heuer - Minitimer"])}
    },
    "error": {
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The connection with your timer has been lost"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer"])}
  },
  "timetable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetable"])}
  },
  "user": {
    "entity": {
      "form": {
        "billing_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing email address"])},
        "email": {
          "explanation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["if you wish to receive invoices on an email other than ", _interpolate(_named("email"))])}
        },
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
      },
      "subtitle": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Information"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My organization"])}
    },
    "ffe": {
      "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some information cannot be modified, it comes from the FFE computer system"])},
      "restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To modify your information, go to the FFE website"])}
    },
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "forbiden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to do this action"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A moment..."])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved changes"])}
    },
    "role": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results Manager"])},
      "chef_piste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trail manager"])},
      "chrono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timekeeper"])},
      "commissaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track marshal"])},
      "juge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judge"])},
      "paddock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paddock Commissioner"])},
      "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])}
    },
    "subtitle": {
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal informations"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "type": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizer"])},
      "prestataire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
      "rider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rider"])},
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
    }
  }
}