export async function test(context, event) {

  //let response = await this.$axios.post('event/' + event.id + '/telegram/test')
  let response = await this.$api('POST', 'event/' + event.id + '/telegram/test')

}

export async function notifyDocument(context, params) {

  try {
    //let response = await this.$axios.post('event/' + params.event_id + '/telegram/document/' + params.document_id)
    let response = await this.$api('POST', 'event/' + params.event_id + '/telegram/document/' + params.document_id)

  } catch (e) {
    throw e.message
  }

  return true
}

export async function notifyTimetable(context, params) {

  try {
    //let response = await this.$axios.post('event/' + params.event_id + '/telegram/timetable/' + params.zone_id)
    let response = await this.$api('POST', 'event/' + params.event_id + '/telegram/timetable/' + params.zone_id)

  } catch (e) {
    throw e.message
  }

  return true
}
