export async function getBillingStats(context, params) {

  this.commit('stats/updateBillingState', null)
  try {
    //let response = await this.$axios.get('stats/billing', )
    let response = await this.$api('GET', 'stats/billing')
    this.commit('stats/updateBillingState', response)
  } catch (e) {}
}

export async function getBillingEntitiesStats(context, params) {

  this.commit('stats/updateBillingEntitiesState', null)
  try {
    //let response = await this.$axios.get('stats/billing/entities', )
    let response = await this.$api('GET', 'stats/billing/entities')
    this.commit('stats/updateBillingEntitiesState', response)
  } catch (e) {}
}

export async function getMapStats(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.from)
    uriParams.append('from', params.from);

  if (params && params.to)
    uriParams.append('to', params.to);

  this.commit('stats/updateMapState', null)
  try {
    //let response = await this.$axios.get('stats/map/entities', {params: uriParams})
    let response = await this.$api('GET', 'stats/map/entities?' + new URLSearchParams(uriParams))
    this.commit('stats/updateMapState', response)
  } catch (e) {}
}

export async function getEventsStats(context, params) {

  const uriParams = new URLSearchParams();

  if (params && params.from)
    uriParams.append('from', params.from);

  if (params && params.to)
    uriParams.append('to', params.to);

  try {
    let response = await this.$api('GET', 'stats/events?' + new URLSearchParams(uriParams))
    this.commit('stats/updateEventsState', null)
    this.commit('stats/updateEventsState', response)
    return response
  } catch (e) {}
}
